import { Application } from '@hotwired/stimulus';
import ejs from 'ejs';

export default ({ translate, components: COMPONENTS, globals }) => {
  const COMPONENTS_WITH_CONTROLLER = Object.entries(COMPONENTS).filter(([, v]) => !!v.controller);

  // we create a list of available `routes`:
  const ROUTES = COMPONENTS_WITH_CONTROLLER
    .reduce((acc, [templateName]) => {
      acc[templateName.replace(/-/g, '_').toUpperCase()] = templateName;
      return acc;
    }, {});

  const renderPage = (route, payload = {}) => {
    const ROOT = document.querySelector('.root');
    const el = document.createElement('div');

    el.setAttribute('data-controller', route);
    Object.entries(payload)
      .forEach(([key, value]) => el.setAttribute(`data-${route}-${key}-value`, value));

    ROOT.innerHTML = '';
    ROOT.append(el);
  };

  const compileTemplate = (templateName) => (args = {}) => {
    const templateWithProperDataAttributes = COMPONENTS[templateName].template.replace('data-target', `data-${templateName}-target`);

    return ejs.compile(
      templateWithProperDataAttributes,
      { client: true },
    )({
      ...args,
      t: translate,
      a: (action) => `${templateName}#${action}`,
      ...globals,
    }, null, (path, d) => compileTemplate(path)(d));
  };

  // we init stimulus
  const stimulus = Application.start();

  // we register available `stimulus` controllers
  COMPONENTS_WITH_CONTROLLER.forEach(([templateName, { controller }]) => {
    controller.ROUTES = ROUTES;
    controller.COMPILED_TEMPLATE = compileTemplate(templateName);
    controller.GO = renderPage;
    controller.T = translate;

    stimulus.register(templateName, controller);
  });

  // by default, page called `root` should be rendered.
  renderPage(ROUTES.ROOT);
};
