{
  "name": "ejs",
  "description": "Embedded JavaScript templates",
  "keywords": [
    "template",
    "engine",
    "ejs"
  ],
  "version": "3.1.10",
  "author": "Matthew Eernisse <mde@fleegix.org> (http://fleegix.org)",
  "license": "Apache-2.0",
  "bin": {
    "ejs": "./bin/cli.js"
  },
  "main": "./lib/ejs.js",
  "jsdelivr": "ejs.min.js",
  "unpkg": "ejs.min.js",
  "repository": {
    "type": "git",
    "url": "git://github.com/mde/ejs.git"
  },
  "bugs": "https://github.com/mde/ejs/issues",
  "homepage": "https://github.com/mde/ejs",
  "dependencies": {
    "jake": "^10.8.5"
  },
  "devDependencies": {
    "browserify": "^16.5.1",
    "eslint": "^6.8.0",
    "git-directory-deploy": "^1.5.1",
    "jsdoc": "^4.0.2",
    "lru-cache": "^4.0.1",
    "mocha": "^10.2.0",
    "uglify-js": "^3.3.16"
  },
  "engines": {
    "node": ">=0.10.0"
  },
  "scripts": {
    "test": "npx jake test"
  }
}
