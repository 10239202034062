import whitelisted from './whitelisted.json';
import { OMIT_SKIP_VERIFICATION_UPON_RETURN_TO_CLIENT, COOKIES_BASED_SKIP_VERIFICATION } from './features';

// data coming in query params:
const { search } = window.location;
const urlSearchParams = new URLSearchParams(search);
const params = Object.fromEntries(urlSearchParams.entries());

const stateQuery = params.state
  ? JSON.parse(atob(params.state))
  : {};

const {
  locale,
  protocol = 'kepler',
  app = 'WebApp',
  url = '',
  clientId = '',
  ui_locales = '',
  type = '',
  skipVerification = false,
} = stateQuery;

// using Map object as a store for app state
export const state = new Map([
  ['locale', locale],
  ['protocol', protocol],
  ['app', app],
  ['url', url],
  ['clientId', clientId],
  ['ui_locales', ui_locales],
  ['type', type],
  ['skipVerification', skipVerification],
  ['code', params.code],
  ['error', params.error],
  ['error_description', params.error_description],
  ['state', params.state],
]);

// omit skipverification in returned `state` query param
const { skipVerification: _, ...returnState } = stateQuery;
const returnParams = new URLSearchParams({
  ...params,
  state: OMIT_SKIP_VERIFICATION_UPON_RETURN_TO_CLIENT
    ? btoa(JSON.stringify(returnState))
    : params.state,
});

// URL/deeplink to client app for OK case:
export const SUCCESS_LINK_HREF = () => (state.get('url')
  ? `${state.get('url')}?${returnParams}`
  : `${state.get('protocol')}://?authenticate=true&${returnParams}`);

// URL/deeplink to client app for NOT OK case:
export const DEFAULT_LINK_HREF = () => (state.get('url')
  ? state.get('url')
  : `${state.get('protocol')}://`);

// URL to auth0 /authorize page
// eslint-disable-next-line no-shadow
export const AUTHORIZE_LINK_HREF = ({ skipVerification = false } = {}) => [
  `${state.get('service.account.login.url')}/authorize?`,
  'response_type=code&',
  `ui_locales=${state.get('ui_locales')}&`,
  `client_id=${state.get('clientId')}&`,
  `redirect_uri=${window.location.origin}&`,
  'scope=openid offline_access&',

  // re-use the state param from the original request
  COOKIES_BASED_SKIP_VERIFICATION
    ? `state=${params.state}&`
    : `state=${btoa(JSON.stringify({ ...stateQuery, skipVerification }))}&`,
  'audience=mycloud.com',
].join('');

// replacing the last slash in the strings, just in case
const checkUrlWhitelisted = () => {
  const u = state.get('url');

  // os5 admin UI
  // ASK TO KEEP THIS PATHNAME SO WE COULD RELY ON IT
  if (u.match('/web/auth0')) return true;

  // rocket & drax admin UI
  // ASK TO KEEP THIS STATE VALUE SO WE COULD RELY ON IT
  if (state.get('app') === 'rocket&drax') return true;

  // other client apps with static URLs listed in the "Allowed Callback URLs"
  const urls = whitelisted
    .map((i) => i.replace(/\/$/, ''));

  return urls.includes(u.replace(/\/$/, ''));
};

const checkProtocolWhitelisted = () => {
  const p = state.get('protocol');

  // inerested only in the protocol part.
  // we are good if it does match
  const protocols = whitelisted
    .map((i) => i.split('://')?.[0]);

  return protocols.includes(p);
};

export const isCallbackWhitelisted = () => (state.get('url')
  ? checkUrlWhitelisted()
  : checkProtocolWhitelisted());

export const isPasswordResetPath = () => (state.get('type') === 'password-reset');
