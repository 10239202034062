import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get APP() {
    return {
      ROUTES: this.constructor.ROUTES,
      go: this.constructor.GO,
      t: this.constructor.T,
    };
  }

  render = (args, options = {}) => {
    const renderedHTML = this.constructor.COMPILED_TEMPLATE(args);

    if (options.replace) {
      const node = new DOMParser()
        .parseFromString(renderedHTML, 'text/html')
        .body
        .firstElementChild;
      this.element.replaceWith(node);
    } else {
      this.element.innerHTML = renderedHTML;
    }
  };

  initialize() {
    // update page title based on the `title` controller instance property
    const title = document.querySelector('title');
    title.innerHTML = this.title || this.APP.t('return-to-app-button-text');
  }

  connect() {
    this.render();
  }
}
