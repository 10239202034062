import Cookies from 'js-cookie';

import Controller from '../framework/base';
import { state, SUCCESS_LINK_HREF } from '../state';
import { COOKIES_BASED_SKIP_VERIFICATION } from '../features';

const open = () => window.location.replace(SUCCESS_LINK_HREF());

export default class extends Controller {
  static targets = ['image'];

  connect() {
    const cookie = Cookies.get('skipVerification');

    if (
      COOKIES_BASED_SKIP_VERIFICATION
      && cookie === state.get('state')
    ) {
      // as it's a final step in the flow,
      // we remove `skipVerification` cookies.
      Cookies.remove('skipVerification');
    }

    if (state.get('url')) {
      // if we redirect back to the web app (no deeplink)
      // we don't need to render this page -
      // we just need to redirect the user to the url provided in `state` query param:
      open();
    } else {
      // if we are about to push the user to deeplink -
      // we need to render the contents in a first place.
      this.render({ href: SUCCESS_LINK_HREF() });

      if (this.imageTarget.complete) {
        // if image is already rendered - open deeplink
        open();
      } else {
        // wait for imare to be rendered an then open deeplink
        this.imageTarget.addEventListener('load', open);
        this.imageTarget.addEventListener('error', open);
      }
    }
  }
}
