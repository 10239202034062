import t from './translate';
import { BUTTON_STATES } from './constants';
import COMPONENTS from './components';
import init from './framework';

init({
  translate: t,
  components: COMPONENTS,
  globals: { BUTTON_STATES },
});
