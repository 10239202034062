import locales, { current } from './locales';
import { state } from './state';

// Falling back to 'en' in case the `locale` passed in `state`
// is not supported for a specific string:
export default (key, options = {}) => {
  const opts = {
    ...options,

    // predefined variables
    app: state.get('app'),
    company_name: 'Western Digital',
  };
  // eslint-disable-next-line import/no-named-as-default-member
  const string = locales[current]?.[key]
    || locales.en[key]
    || `[missing translation: ${key}]`;

  return string.replace(/\{{(.+?)}}/g, (_, match) => opts[match]);
};
