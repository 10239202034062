/* eslint-disable import/no-unresolved */
// .ejs templates:
import rootTemplate from 'bundle-text:../templates/root.html';
import verifyEmailTemplate from 'bundle-text:../templates/verify-email.html';
import changeEmailTemplate from 'bundle-text:../templates/change-email.html';
import errorAuth0Template from 'bundle-text:../templates/error-auth0.html';
import successGoToAppTemplate from 'bundle-text:../templates/success-go-to-app.html';
import checkTemplate from 'bundle-text:../templates/check.html';
import loaderTemplate from 'bundle-text:../templates/loader.html';
import buttonPrimaryTemplate from 'bundle-text:../templates/button-primary.html';
import buttonSecondaryTemplate from 'bundle-text:../templates/button-secondary.html';
import footerTemplate from 'bundle-text:../templates/footer.html';
import buttonTemplate from 'bundle-text:../templates/button.html';
import infoTemplate from 'bundle-text:../templates/info.html';
import passwordResetTemplate from 'bundle-text:../templates/password-reset.html';

// `stimulus` controllers:
import rootController from './controllers/root';
import errorAuth0Controller from './controllers/error-auth0';
import successGoToAppController from './controllers/success-go-to-app';
import verifyEmailController from './controllers/verify-email';
import changeEmailController from './controllers/change-email';
import footerController from './controllers/footer';
import passwordResetController from './controllers/password-reset';

export default {
  // page-level components (with associated controller):
  'change-email': { template: changeEmailTemplate, controller: changeEmailController },
  'error-auth0': { template: errorAuth0Template, controller: errorAuth0Controller },
  'footer': { template: footerTemplate, controller: footerController },
  'password-reset': { template: passwordResetTemplate, controller: passwordResetController },
  'root': { template: rootTemplate, controller: rootController },
  'success-go-to-app': { template: successGoToAppTemplate, controller: successGoToAppController },
  'verify-email': { template: verifyEmailTemplate, controller: verifyEmailController },

  // shared components (without associated controller):
  'loader': { template: loaderTemplate },
  'check': { template: checkTemplate },
  'button-primary': { template: buttonPrimaryTemplate },
  'button-secondary': { template: buttonSecondaryTemplate },
  'button': { template: buttonTemplate },
  'info': { template: infoTemplate },
};
