import Controller from '../framework/base';
import { updateUserEmail, requestEmailVerification } from '../apis/authservice';
import { state } from '../state';
import { BUTTON_STATES } from '../constants';
import { redirectToAuth0AuthorizeForm } from '../common';

export default class extends Controller {
  static values = {
    submitButtonState: { type: String, default: BUTTON_STATES.DEFAULT },
    input: { type: String, default: '' },
    error: { type: String, default: '' },
  };

  _render() {
    this.render({
      submitButtonState: this.submitButtonStateValue,
      value: this.inputValue,
      error: this.errorValue,
    });
  }

  connect() { this._render(); }

  submitButtonStateValueChanged() { this._render(); }

  onInputChange = (e) => {
    this.inputValue = e.target.value;
  };

  submit = (e) => {
    const formData = new FormData(e.target);
    const user = state.get('user');
    const token = state.get('access_token');

    e.preventDefault();
    e.stopPropagation();

    this.errorValue = '';
    this.submitButtonStateValue = BUTTON_STATES.REQUEST;

    updateUserEmail({
      email: formData.get('email'),
      userId: user.sub,
      token,
    }).then((r) => {
      const desc = 'The specified new email already exists';
      if (
        r._status === 400
        && (r.error_description === desc || JSON.parse(r.error_description) === desc)
      ) throw new Error('USER_WITH_SUCH_EMAIL_EXISTS');
      if (r._status !== 200) throw new Error(r._status);

      // store udpated user
      state.set('user', { ...state.get('user'), ...r.data });

      return r;
    }).then(() => {
      return requestEmailVerification({
        token: state.get('access_token'),
        userId: state.get('user').sub,
      }).then((resp) => {
        if (resp._status !== 201) throw new Error(resp._status);

        // go to verify email if everything is good
        return this.APP.go(this.APP.ROUTES.VERIFY_EMAIL);
      });
    }).catch((err) => {
      if (err.message === 'USER_WITH_SUCH_EMAIL_EXISTS') {
        this.errorValue = this.APP.t('error-user-exists');
        this.submitButtonStateValue = BUTTON_STATES.DEFAULT;
      } else if (err.message === '401') {
        // handling the 401 for email change gracefully
        // by pushing the user to /authorize
        redirectToAuth0AuthorizeForm({ skipVerification: false });
      } else {
        // show error otherwise
        this.errorValue = this.APP.t('inline-error');
        this.submitButtonStateValue = BUTTON_STATES.DEFAULT;
      }
    });
  };

  goBack = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.APP.go(this.APP.ROUTES.VERIFY_EMAIL);
  };
}
