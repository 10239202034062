import Controller from '../framework/base';
import { state, DEFAULT_LINK_HREF } from '../state';

export default class extends Controller {
  onLinkClick() {
    window.location.href = DEFAULT_LINK_HREF();
  }

  connect() {
    // error_description -> translation key mapping:
    const description = {
      'The transaction has expired': this.APP.t('error-page-two-factor-code-expired-error'),
      'user is blocked, please change your password': this.APP.t('error-page-blocked-user-error'),
      'user is blocked': this.APP.t('error-page-blocked-user-error2'),
    }[state.get('error_description')] || state.get('error_description') || '';

    this.render({
      description,
      showButtons: !['Sonos'].includes(state.get('app')),
    });
  }
}
